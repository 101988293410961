import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {IWixStyleParams} from '@wix/native-components-infra/dist/src/types/wix-sdk'
import {
  BiParams,
  createUouBiMiddlewareWithBiParams,
  isRtlLanguage,
  PAID_PLANS_APP_DEF_ID,
  PAID_PLANS_SECTION_ID,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {decodeInstance} from '../../commons/selectors/instance'
import {ErrorMonitor, initSentry} from '../../commons/services/error-monitor'
import {importResources} from '../../commons/services/import-18n-resources'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageUrl, isSchedulePageInstalled, isSSR} from '../../commons/utils/wix-code-api'
import {getExtraEventData} from '../actions/event'
import {addLoginListener, fetchCurrentMember} from '../actions/members'
import {addLocationListener, handleInitialNavigation} from '../actions/navigation'
import {getMemberRsvp} from '../actions/rsvp'
import * as eventsUou from '../bi/uou-bi-events-map'
import {DetailsPageProps} from '../components/app/interfaces'
import {datesMiddleware} from '../middlewares/date'
import reducers from '../reducers'
import {getDemoEvent} from '../services/demo-event'
import {createDetailsPageFedopsLogger} from '../services/fedops'
import {FedopsLogger, State, StoreExtraArgs} from '../types'
import {userEventsLogger} from '../user-events-logger'
import {Api} from '../utils/api'
import {parseLocation} from '../utils/navigation'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {getDraftToken} from '../utils/query'
import {getExportedActions} from './exported-actions'

const DSN = 'https://588ca41dab294885b034d58caffde32b@sentry.wixpress.com/310'

export const createDetailsPageController = async (controller: IWidgetControllerConfig, experiments: ExperimentsBag) => {
  const monitor = initSentry(controller, DSN)
  return Promise.resolve({
    pageReady: monitor.withErrorBoundary(() => pageReady(controller, monitor, experiments)),
  })
}

const pageReady = async (controller: IWidgetControllerConfig, monitor: ErrorMonitor, experiments: ExperimentsBag) => {
  const {wixCodeApi} = controller
  const {staticsBaseUrl} = controller.appParams.baseUrls
  const {onAppLoaded, onSSRPageReady, ...fedopsLogger} = createDetailsPageFedopsLogger(controller)
  const language = getLanguage(wixCodeApi)
  const translationsPromise = importResources(['page', 'accessibility', 'demo-data'], language, staticsBaseUrl)
  const ssr = isSSR(controller.wixCodeApi)

  const serverApi = new Api(controller)
  const [pageUrl, initialData, paidPlansEnabled, membersAreaEnabled] = await Promise.all([
    getPageUrl(wixCodeApi),
    getInitialData(serverApi, controller, controller.config.style.styleParams),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_DEF_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
    isMembersEventsPageInstalled(wixCodeApi),
  ])
  const store = createStore(
    controller,
    fedopsLogger,
    experiments,
    {...initialData, membersAreaEnabled, paidPlansEnabled},
    serverApi,
    monitor,
  )

  const [translations] = await Promise.all([
    translationsPromise,
    store.dispatch(setBaseEnvironment() as any),
    store.dispatch(fetchCurrentMember() as any),
  ])

  await store.dispatch(getMemberRsvp() as any)

  store.dispatch(addLoginListener() as any)

  watchInstance(controller, store.dispatch)

  await store.dispatch(handleInitialNavigation() as any)

  addLocationListener(controller.wixCodeApi, store)
  subscribeToStateChanges(controller, store)

  const actions = getExportedActions(store, onAppLoaded, monitor)

  const props: DetailsPageProps = {
    state: store.getState(),
    actions,
    isRTL: isRtlLanguage(language),
    staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
    pageUrl,
    translations,
  }

  controller.setProps(props)

  if (!ssr) {
    const event = store.getState().event
    store.dispatch(getExtraEventData(event) as any)
  }

  if (ssr) {
    onSSRPageReady()
  }
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUou)]

const createStore = (
  controller: IWidgetControllerConfig,
  fedopsLogger: FedopsLogger,
  experiments: ExperimentsBag,
  initialData,
  serverApi,
  monitor,
) => {
  const {wixCodeApi, compId, platformAPIs, appParams} = controller

  const biMiddleware = createBiMiddleware({
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  })

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  return createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: {...initialData, ...(controller as any).testState, experiments},
    extraArguments: {serverApi, wixCodeApi, compId, platformAPIs, fedopsLogger, monitor},
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })
}

const getInitialData = async (
  serverApi: Api,
  controller: IWidgetControllerConfig,
  styleParams: IWixStyleParams,
): Promise<Partial<State>> => {
  const {wixCodeApi, appParams, config} = controller
  const navigation = parseLocation(wixCodeApi)
  const {slug} = navigation
  const responsiveEditor = isResponsiveEditor(config)
  const draftPreviewToken = getDraftToken(wixCodeApi)

  const schedulePageInstalled = await isSchedulePageInstalled(wixCodeApi)
  const currentUser = wixCodeApi.user.currentUser

  const [{event, component, siteSettings, demoEvents, tickets, policies, schedule, dates}] = await Promise.all([
    serverApi.getData({
      slug,
      responsive: responsiveEditor,
      schedulePageInstalled,
      draftPreviewToken,
    }),
  ])
  const instance = appParams.instance

  return {
    event: !event && demoEvents ? getDemoEvent(demoEvents, slug, responsiveEditor) : event,
    siteSettings,
    demoEvents,
    tickets,
    schedule,
    multilingual: getMultilingualInitialState(wixCodeApi),
    navigation,
    component: {
      id: component.id,
      settings: {
        ...component.config.settings,
        ...styleParams.numbers,
        ...styleParams.booleans,
      },
    },
    instance: {
      instance,
      ...decodeInstance(instance),
    },
    policies: {
      agreed: false,
      ...policies,
    },
    dates,
    currentUser: {
      id: currentUser.id,
      role: currentUser.role,
      loggedIn: currentUser.loggedIn,
    },
  }
}
