import {IWixAPI} from '@wix/native-components-infra/dist/es/src/types/types'
import {
  isTicketed,
  EVENT_CATEGORY,
  EVENTS,
  LIST,
  ORIGIN,
  getEventTitle,
  TICKET_TYPE,
  getCouponDiscountCode,
} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {ITrackEventName} from '@wix/native-components-infra/dist/es/src/types/wix-sdk'
import {trackEvent} from '../../commons/utils/wix-code-api'
import {State} from '../types'
import {DETAILS_PAGE_LOADED} from '../actions/loaded'
import {SELECT_TICKET, UNSELECT_TICKET} from '../actions/selected-tickets'
import {RESERVE_TICKETS} from '../actions/reservation'
import {PAYMENT_METHOD_SELECTED, PLACE_ORDER_BUTTON_CLICKED} from '../actions/payment'
import {NEXT_FORM_CLICKED} from '../actions/checkout'
import {COUPON_APPLIED} from '../actions/coupon'
import {SEND_RSVP} from '../actions/rsvp'
import {REGISTRATION_BUTTON_CLICKED} from '../actions/registration'

export const userEventsLogger =
  ({wixCodeApi}: {wixCodeApi: IWixAPI}) =>
  ({getState}) =>
  (next: Function) =>
  (action: AnyAction) => {
    switch (action.type) {
      case DETAILS_PAGE_LOADED:
        detailsPageLoaded(wixCodeApi, getState())
        break
      case SELECT_TICKET:
        addRemoveTicket(wixCodeApi, getState(), action, EVENTS.AddToCard)
        break
      case UNSELECT_TICKET:
        addRemoveTicket(wixCodeApi, getState(), action, EVENTS.RemoveFromCart)
        break
      case RESERVE_TICKETS.SUCCESS:
        reserveTicketsSuccess(wixCodeApi, getState())
        break
      case PAYMENT_METHOD_SELECTED:
        paymentMethodSelected(wixCodeApi, action)
        break
      case PLACE_ORDER_BUTTON_CLICKED:
        placeOrderButtonClicked(wixCodeApi, getState())
        break
      case NEXT_FORM_CLICKED:
        nextFormClicked(wixCodeApi)
        break
      case COUPON_APPLIED:
        couponApplied(wixCodeApi)
        break
      case REGISTRATION_BUTTON_CLICKED:
        registrationButtonClicked(wixCodeApi, getState())
        break
      case SEND_RSVP.REQUEST:
        sendRsvpRequest(wixCodeApi, getState())
        break
      default:
        break
    }

    return next(action)
  }

const detailsPageLoaded = (wixCodeApi: IWixAPI, {event}: State) => {
  if (isTicketed(event)) {
    trackEvent(wixCodeApi, EVENTS.ViewContent, {
      origin: ORIGIN,
      name: getEventTitle(event),
      list: LIST,
    } as any)
  } else {
    trackEvent(wixCodeApi, EVENTS.CustomEvent, {
      event: 'Name:rsvpContentView',
      name: getEventTitle(event),
      eventCategory: EVENT_CATEGORY,
      eventLabel: ORIGIN,
    } as any)
  }
}

const addRemoveTicket = (wixCodeApi: IWixAPI, {tickets}: State, action: AnyAction, type: ITrackEventName) => {
  const selectedTicketId = action.payload.ticketId
  const idsEqual = (ticket: wix.events.ticketing.TicketDefinition) => ticket.id === selectedTicketId
  const {
    price: {amount, currency},
    free,
    name,
  } = tickets.find(idsEqual)
  const position = tickets.findIndex(idsEqual) + 1
  trackEvent(wixCodeApi, type, {
    origin: ORIGIN,
    name,
    price: amount,
    currency,
    variant: free ? TICKET_TYPE.Free : TICKET_TYPE.Regular,
    position,
    quantity: 1,
  })
}

const reserveTicketsSuccess = (wixCodeApi: IWixAPI, {selectedTickets, tickets}: State) => {
  const contents = Object.keys(selectedTickets).map((ticketId) => {
    const idsEqual = (ticket: wix.events.ticketing.TicketDefinition) => ticket.id === ticketId
    const {
      name,
      price: {amount, currency},
      free,
    } = tickets.find(idsEqual)
    const position = tickets.findIndex(idsEqual) + 1
    return {
      name,
      price: amount,
      currency,
      variant: free ? TICKET_TYPE.Free : TICKET_TYPE.Regular,
      quantity: selectedTickets[ticketId],
      position,
    }
  })
  trackEvent(wixCodeApi, EVENTS.InitiateCheckout, {
    origin: ORIGIN,
    contents,
  })
}

const paymentMethodSelected = (wixCodeApi: IWixAPI, action: AnyAction) =>
  trackEvent(wixCodeApi, EVENTS.AddPaymentInfo, {
    origin: ORIGIN,
    option: action.payload.option,
  })

const placeOrderButtonClicked = (wixCodeApi: IWixAPI, {placedOrder}: State) => {
  const {invoice, orderNumber} = placedOrder.order

  const {items, revenue, tax} = invoice

  const contents = items.map((ticket) => ({
    id: ticket.id,
    name: ticket.name,
    quantity: ticket.quantity,
    price: Number(ticket.price.amount),
    currency: ticket.price.currency,
  }))

  trackEvent(wixCodeApi, EVENTS.Purchase, {
    id: orderNumber,
    origin: ORIGIN,
    revenue: Number(revenue?.amount),
    tax: Number(tax?.amount.amount),
    coupon: getCouponDiscountCode(invoice),
    contents,
  })
}

const nextFormClicked = (wixCodeApi: IWixAPI) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'addAssigned',
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)

const couponApplied = (wixCodeApi: IWixAPI) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'addCoupon',
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)

const registrationButtonClicked = (wixCodeApi: IWixAPI, {event}: State) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'Name:rsvpRegisterNext',
    name: getEventTitle(event),
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)

const sendRsvpRequest = (wixCodeApi: IWixAPI, {event}: State) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'Name:rsvpSubmit',
    name: getEventTitle(event),
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)
